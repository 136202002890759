<script lang="ts">
	import StaticVideoInner from "./StaticVideoInner.svelte";
	import type { SourceSetQuery } from "../../utils/SourceSetQuery.js";

	export let video: string;
	export let containerClass = "";
	export { containerClass as class };
	export let width: number | undefined = undefined;
	export let height: number | undefined = undefined;
	export let noLoop = false;
	export let autoplay = true;
	export let controls = false;
	export let notMuted = false;
	export let dontPlayInline = false;
	export let videoClass = "";
	export let poster: string | undefined = undefined;
	export let loading: "lazy" | "eager" = "lazy";
	export let sourcesets: Partial<Record<SourceSetQuery, string>> = {};
	export let element: HTMLVideoElement | null = null;
	export let disabled = false;
</script>

<div class="{containerClass} group relative" on:mouseover on:focus on:mouseout on:blur role="button" tabindex="0">
	<StaticVideoInner
		bind:element
		{video}
		{width}
		{height}
		{noLoop}
		{autoplay}
		{videoClass}
		{poster}
		{controls}
		muted={!notMuted}
		playsinline={!dontPlayInline}
		lazy={loading === "lazy"}
		{sourcesets}
		{disabled}
	/>
</div>
